import React from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { kebabCase } from "lodash";

import { Icon } from "lib/@cms/components/primitive";
import withErrorBoundaryHOC from "lib/@cms/hocs/withErrorBoundary";
import { FormApplicationTemplate } from "lib/@forms/components/primitive";
import CONTACT_US_TEMPLATE from "data/forms-templates/contact-us.json";

function Contact({ data }) {
  if (!data) return null;

  return (
    <div className="tw-flex tw-flex-wrap tw-justify-between">
      <div className="md:tw-w-6/12 lg:tw-w-7/12">
        <Message data={data} />
      </div>
      <div className="md:tw-w-5/12 lg:tw-w-4/12">
        <MainContact data={data} />
      </div>
    </div>
  );
}

export default withErrorBoundaryHOC(Contact);

// --- Components ---

function Message({ data }) {
  async function onSubmitHandler(
    formValues,
    { setSubmitting = () => null, resetForm = () => null },
  ) {
    try {
      setSubmitting(true);

      await axios.post(
        `${process.env.GATSBY_API_URL}/contact-us`,
        { ...formValues, cu: kebabCase(process.env.GATSBY_CU_NAME) },
        {
          headers: { "Content-Type": "application/json" },
        },
      );

      toast.success("Your form has been successfully submitted.", { toastId: "onSubmit" });

      setSubmitting(false);
      resetForm();
    } catch (error) {
      console.log(error);
      toast.error("There was an error submitting the form.", {
        toastId: "fetch-error-ups",
      });
    }
  }

  return (
    <div className="tw-w-full">
      <h3 className="bl-font-secondary tw-font-semibold bl-text-primary-180 bl-text-xl">
        {data.formTitle}
      </h3>

      <FormApplicationTemplate
        centerSubmitBtn={false}
        data={CONTACT_US_TEMPLATE}
        securedFormFriendlyName={process.env.GATSBY_SECURED_FORM_CONTACT_US}
        submitButtonText="Submit"
        buttonStyles="bl-text-xxs tw-mb-8"
        onSubmit={onSubmitHandler}
        noPadding
        noMargin
        noBackground
      />
    </div>
  );
}

function MainContact({ data }) {
  return (
    <div className="tw-w-full">
      <h3 className="bl-font-secondary tw-font-semibold bl-text-primary-180 bl-text-xl tw-mb-10">
        {data.mainContactTitle}
      </h3>
      <div>
        {data.socialMedia.map((element, index) => {
          return (
            <MainContactBox
              key={`MainContactBox-${index}`}
              label={element.label}
              text={element.value}
            />
          );
        })}
      </div>
    </div>
  );
}

function MainContactBox({ label, text }) {
  return (
    <div className="tw-flex tw-items-center tw-mb-5">
      <IconSelector label={label} />
      <p className="bl-font-primary bl-text-grayscale">{text}</p>
    </div>
  );
}

function IconSelector({ label }) {
  if (label === "email") {
    return <Icon icon={Icon.icon.ENVELOPE} className="tw-mr-3" />;
  }

  if (label === "telephoneNumber") {
    return <Icon icon={Icon.icon.PHONE} className="tw-mr-3" />;
  }

  if (label === "facebook") {
    return <Icon icon={Icon.icon.FACEBOOK_SIMPLE} className="tw-mr-3" />;
  }

  if (label === "instagram") {
    return <Icon icon={Icon.icon.INSTAGRAM} className="tw-mr-3" />;
  }

  return <div className="tw-w-6 tw-h-6 bl-bg-primary tw-mr-3" />;
}
