import React from "react";
import GoogleMapReact from "google-map-react";

import Icon from "lib/@cms/components/primitive/Icon";
import withErrorBoundaryHOC from "lib/@cms/hocs/withErrorBoundary";

const VARIANTS = {
  EXPANDED: "EXPANDED",
};

function Branch({ variant = "EXPANDED", ...props }) {
  if (!props.data) return null;

  if (variant === VARIANTS.EXPANDED) {
    return <BranchExpanded {...props} />;
  }

  throw new Error(`Invalid Branch variant: ${variant}`);
}

Branch.variant = VARIANTS;

export default withErrorBoundaryHOC(Branch);

// --- Components ---

function BranchExpanded({ data }) {
  return (
    <div className="tw-flex tw-flex-col tw-mt-16">
      <div className="tw-flex tw-flex-wrap tw-justify-between">
        <h3 className="bl-font-secondary tw-font-semibold bl-text-primary-180 bl-text-xl tw-mr-5">
          {data.title}
        </h3>
        <div className="tw-flex tw-items-center sm:tw-flex-row sm:tw-w-56 sm:tw-text-right">
          <p className="bl-text-grayscale-100 tw-leading-none tw-mr-2">{data.direction}</p>
          <Icon icon={Icon.icon.PIN} />
        </div>
      </div>
      <div className="tw-flex tw-flex-wrap tw-justify-between tw-mt-10">
        <div className="sm:tw-w-5/12 tw-pr-6">
          <MainOffice data={data} />
        </div>
        <div className="tw-w-full sm:tw-w-7/12 tw-rounded-xl tw-overflow-hidden">
          <GoogleMap lat={data.latitudeMap} lng={data.longitudeMap} />
        </div>
      </div>
    </div>
  );
}

function MainOffice({ data }) {
  return (
    <div>
      <div>
        <h5 className="bl-font-secondary tw-font-semibold bl-text-primary">{data.titleHours}</h5>
        {data.officeHoursDetails.map((item, index) => {
          return (
            <OfficeHourBox
              key={`OfficeHourBox-${index}`}
              days={item.days}
              hours={item.hours}
              info={item.info}
            />
          );
        })}
      </div>
      <div className="tw-my-10">
        {data.contactSimple.map((item, index) => {
          return (
            <ContactSimple
              key={`ContactSimple-${index}`}
              title={item.title}
              info={item.info}
              items={item.items}
            />
          );
        })}
      </div>
    </div>
  );
}

function OfficeHourBox({ days, hours, info }) {
  return (
    <div className="tw-w-48 tw-mb-5">
      <p className="tw-font-medium bl-text-xs bl-text-grayscale">{days}</p>
      <p className="bl-text-grayscale-100 tw-leading-none">{hours}</p>
      <p className="tw-mt-1 tw-leading-none tw-font-medium bl-text-xs bl-text-grayscale-50 tw-italic">
        {info}
      </p>
    </div>
  );
}

function ContactSimple({ title, info, items }) {
  return (
    <div className="tw-mb-4">
      <h5 className="bl-font-secondary tw-font-semibold bl-text-primary tw-leading-none">
        {title}
      </h5>
      <p className="tw-mb-3 tw-leading-none tw-font-medium bl-text-xs bl-text-grayscale-50 tw-italic">
        {info}
      </p>
      <div>
        {items &&
          items.map((item, index) => {
            return (
              <p key={`ContactSimple-item-${index}`} className="bl-text-grayscale-100 tw-leading-4">
                {item.value}
              </p>
            );
          })}
      </div>
    </div>
  );
}

function GoogleMap({ lat, lng }) {
  const defaultProps = {
    center: {
      lat,
      lng,
    },
    zoom: 17,
  };

  return (
    <div className="tw-h-160">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <Place lat={defaultProps.center.lat} lng={defaultProps.center.lng} />
      </GoogleMapReact>
    </div>
  );
}

function Place() {
  return (
    <div
      style={{ transform: "translate(-50%, -50%)" }}
      className="tw-w-12 tw-h-12 tw-rounded-full bl-bg-primary-50 tw-flex tw-items-center tw-justify-center tw-opacity-75"
    >
      <div className="tw-w-4 tw-h-4 tw-rounded-full bl-bg-primary" />
    </div>
  );
}
