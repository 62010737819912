import React from "react";

import Page from "lib/@cms/components/layout/Page";
import Callout from "lib/@cms/components/cms/Callout";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import Contact from "lib/@cms/components/pages/contact-us/Contact";
import Branch from "lib/@cms/components/pages/contact-us/Branch";
import Divider from "lib/@cms/components/primitive/Divider";

const ContactUsPage = () => {
  return (
    <Page pathname="contact-us">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENTS}
              color={PageHeader.color.GRAYSCALE}
              data={data.PageHeaderGradients}
            />
            <Divider className="tw-my-6" />

            <div className="tw-container">
              <Contact data={data.Contact} />
              <Divider className="bl-bg-grayscale-6 tw-my-8" />

              <Branch data={data.BranchExpanded[0]} />
              <Divider className="tw-my-10" />

              {Array.isArray(data.CalloutMobileApps) && data.CalloutMobileApps.length > 0 ? (
                <React.Fragment>
                  <Callout variant={Callout.variant.MOBILE_APPS} data={data.CalloutMobileApps[0]} />
                </React.Fragment>
              ) : null}
            </div>
            <Divider className="tw-my-8 sm:tw-my-12" />
          </React.Fragment>
        );
      }}
    </Page>
  );
};

export default ContactUsPage;
